.header-section {
  height: $hero-height;
  position: relative;
  @include box-shadow(bottom2);
}
.hero-section {
  height: 90vh;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 10;
}
.company-highlight-section {
  background-image: linear-gradient(
    to right bottom,
    $color-white,
    $color-white
  );
  background-size: cover;
  background-repeat: no-repeat;
}

.glance-section {
  margin-top: $sp-96;
}

.about-section {
  margin-top: $sp-96;
  background-color: $color-primary-super-light;
  padding: $sp-96 0;
  color: $color-text-light;
}

.contact-section {
  background-image: linear-gradient(
      105deg,
      rgba($color-white, 0.9) 0%,
      rgba($color-primary-super-light, 0.9) 50%,
      rgba($color-primary, 0.8) 50%
    ),
    url("../../img/contact-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cta_section {
  background-image: linear-gradient(
    to right,
    rgba($color-primary-dark, 0.9),
    rgba($color-primary-light, 0.9)
  );
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
