@import "../../sass/common.scss";
.content {
  &-glance {
    @extend %flex-content;
    @include respond(tab-port) {
      flex-direction: column;
    }
    &__left {
      @extend %flex-content-text;
    }
    &__right {
      @extend %flex-content-image;
      background-image: linear-gradient(
          to right bottom,
          rgba($color-secondary-light, 0.5),
          rgba($color-secondary-dark, 0.5)
        ),
        url(../../img/content-1.jpg);
      background-position: bottom;
    }
  }
}
