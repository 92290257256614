@import "../../sass/common.scss";
.contact {
  display: flex;
  gap: 2rem;
  @include respond(tab-port) {
    flex-direction: column-reverse;
    gap: 0;
  }
  &__form {
    flex: 1.5;
    padding: $sp-80 0;
    @include respond(tab-port) {
      padding: unset;
      padding-bottom: $sp-64;
    }
  }
  &__text {
    flex: 1;
    align-self: center;
  }
  &__fig {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__icon {
    fill: rgba($color-secondary-light, 0.9);
  }
}
