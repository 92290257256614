@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: $color-grey-dark;
}

.heading {
  @extend %font-heading;
  &-primary {
    font-size: $ft-44;
    @include respond(tab-land) {
      font-size: $ft-36;
    }
    @include respond(tab-port) {
      font-size: $ft-30;
    }
    @include respond(phone) {
      font-size: $ft-24;
    }
  }
  &-secondary {
    font-size: $ft-36;
    @include respond(tab-land) {
      font-size: $ft-30;
    }
    @include respond(tab-port) {
      font-size: $ft-24;
    }
    @include respond(phone) {
      font-size: $ft-20;
    }
  }
  &-tertiary {
    font-size: $ft-20;
    @include respond(tab-land) {
      font-size: $ft-18;
    }
    @include respond(tab-port) {
      font-size: $ft-16;
    }
    @include respond(phone) {
      font-size: $ft-14;
    }
  }
  &-list {
    font-size: $ft-16;
    font-weight: 600;
    color: $color-secondary-dark;
    margin-bottom: $sp-12;
    @include respond(tab-land) {
      font-size: $ft-14;
    }
    @include respond(tab-port) {
      font-size: $ft-12;
    }
  }
  &-banner {
    font-size: $ft-74;
    text-transform: uppercase;
    color: $color-secondary-light;
    font-weight: 700;
    text-shadow: 0 1.5rem 0.5rem rgba($color: #000000, $alpha: 0.5);
    @include respond(tab-land) {
      font-size: $ft-62;
    }
    @include respond(tab-port) {
      font-size: $ft-52;
    }
    @include respond(phone) {
      font-size: $ft-44;
      text-align: center;
    }
  }
}

.para {
  @extend %font-default;
  margin-bottom: $sp-24;
  @include respond(phone) {
    font-size: $ft-14;
  }
}
