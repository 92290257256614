.alert {
  &-heading.h4 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.5rem;
  }
  &-80 {
    width: 80%;
  }
}
