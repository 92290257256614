@import "../../../sass/common.scss";
.list {
  list-style: none;
  &__item {
    padding-left: $sp-24;
    display: flex;
    gap: $sp-2;
    line-height: 1.2;
    align-items: center;
    font-size: $ft-16;
    color: $color-secondary-dark;
    &::before {
      content: "\00bb";
      font-size: $ft-24;
      margin-top: -$sp-4;
      color: currentColor;
    }
  }
}
