*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  @include respond(big-desktop) {
    font-size: 75%; //1 rem = 12px; 12px/16px = 75%
  }
  @include respond(mid-desktop) {
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  }
  @include respond(tab-land) {
    font-size: 56.25%; //1 rem = 9px;
  }
  @include respond(tab-port) {
    //font-size: 50%; //1 rem = 8px;
  }
}

body {
  box-sizing: border-box;
  background-image: linear-gradient(
    to right bottom,
    $color-white,
    $color-white
  );
  background-size: cover;
  background-repeat: no-repeat;
}

.wrapper {
  max-width: 200rem;
  overflow: hidden;
  margin: 0 auto;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  position: relative;
  @include respond(tab-port) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
