@import "../../sass/common.scss";
.reputation {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: stretch;
  justify-items: stretch;
  padding: $sp-16 $sp-32;
  row-gap: 2rem;
  column-gap: 2rem;
  &__fig {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: $sp-12;
    padding-bottom: $sp-16;
    @include box-shadow(bottom1);
  }

  &__icon {
    width: 6rem;
    height: 6rem;
    border: 2px solid $color-primary;
    border-radius: 50%;
    fill: $color-primary;
    padding: $sp-12 0;
    @include box-shadow(light);
    overflow: visible;
  }
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $sp-8;
    font-size: $ft-14;
    text-align: center;
    font-weight: 400;
  }
  &__bold {
    font-size: $ft-18;
    font-weight: 600;
    color: $color-primary;
  }
}
