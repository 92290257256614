@import "../../sass/common.scss";
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: $hero-height;
  width: 100%;
  z-index: -1;
  overflow: hidden;

  &__content {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &::before {
    content: "";
    position: absolute;
    left: -15rem;
    width: 70%;
    height: 100%;
    background-color: rgba($color-white, 0.8);
    transform: skew(-15deg);
    @include respond(tab-port) {
      left: -20rem;
      width: 80%;
    }
    @include respond(phone) {
      width: 100%;
      background-color: rgba($color-white, 0.6);
      left: unset;
      transform: unset;
    }
  }
}
