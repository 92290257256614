@import "../../sass/common.scss";
.vm-block {
  margin: $sp-64 0;
  display: flex;
  align-items: stretch;
  gap: $sp-24;
  @include respond(tab-port) {
    flex-direction: column;
  }
  &__fig {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $sp-24;
    padding: $sp-16;
    @include background-gradiant(to right bottom, secondary);
    @include box-shadow(light);
    border-radius: 1rem;
    outline: 0.5rem solid transparent;
    transition: all 0.4s, outline 0.4s linear;

    &:hover {
      @include box-shadow(mid);
      outline-color: $color-white;
    }
  }
  &__fig:hover &__icon {
    @include box-shadow(mid);
  }
  &__fig:hover &__heading {
    transform: scale(1.1);
    text-shadow: 0 1.5rem 0.5rem rgba($color-black, 0.4);
  }
  &__icon {
    width: 10rem;
    height: 10rem;
    padding: $sp-12;
    border-radius: 4rem;
    fill: $color-white;
    @include box-shadow(light);
    transition: all 0.2s;
  }
  &__caption {
    color: $color-white;
  }
  &__heading {
    margin-bottom: $sp-24;
    color: currentColor;
    transition: all 0.3s;
  }
}
