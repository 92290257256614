@import "../../sass/common.scss";
.cta-box {
  padding: $sp-48 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $sp-24;
  @include respond(tab-port) {
    flex-direction: column;
  }
  &__text {
    flex: 2;
    .heading-banner {
      font-size: $ft-52;
      color: $color-primary-super-light;
      @include respond(phone) {
        text-align: center;
      }
    }
  }
  &__cta {
    flex: 1;
  }
  &__link:link,
  &__link:visited {
    font-weight: 600;
    padding: $sp-12 $sp-32;
    width: 100%;
    text-align: center;
  }
}
