// COLORS
$color-primary: #0f57a6;
$color-primary-light: #acc8e5;
$color-primary-super-light: #e7eef6;
$color-primary-dark: #0e4e95;
$color-text-light: #112a46;

$color-secondary-light: #4dabf7;
$color-secondary-dark: #1864ab;

$color-tertiary-light: #94d82d;
$color-tertiary-dark: #66a80f;

$color-error-light: #ff6b6b;
$color-error-dark: #c92a2a;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #333;
$color-grey-dark-2: #999;

$color-white: #fff;
$color-black: #000;

// FONT
$ft-10: 1rem;
$ft-12: 1.2rem;
$ft-14: 1.4rem;
$ft-16: 1.6rem;
$ft-18: 1.8rem;
$ft-20: 2rem;
$ft-24: 2.4rem;
$ft-30: 3rem;
$ft-36: 3.6rem;
$ft-44: 4.4rem;
$ft-52: 5.2rem;
$ft-62: 6.2rem;
$ft-74: 7.4rem;
$ft-86: 8.6rem;
$ft-98: 9.8rem;

// SPACING SYSTEM
$sp-2: 0.2rem;
$sp-4: 0.4rem;
$sp-8: 0.8rem;
$sp-12: 1.2rem;
$sp-16: 1.6rem;
$sp-24: 2.4rem;
$sp-32: 3.2rem;
$sp-48: 4.8rem;
$sp-64: 6.4rem;
$sp-80: 8rem;
$sp-96: 9.6rem;
$sp-128: 12.8rem;

//SHADOW
$shadow-light-1: 2rem;

$hero-height: 85vh;
