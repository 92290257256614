%flex-content {
  display: flex;
  justify-content: space-between;
  gap: $sp-64;
  &-text {
    flex: 1.5;
  }
  &-image {
    flex: 1;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 2rem;
  }
}

%font {
  &-heading {
    font-weight: 700;
    text-transform: uppercase;
    @include background-gradiant(to left bottom, primary);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  &-default {
    font-size: $ft-16;
    line-height: 1.3;
  }
}

%text-moon-effect {
  text-shadow: 0 1rem 2rem rgba(black, 0.4);
}
