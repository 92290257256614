@import "../../sass/common.scss";
.navigation {
  width: 100%;
  &__checkbox {
    display: none;
  }
  &__button {
    background-color: $color-white;
    position: fixed;
    height: 5rem;
    width: 5rem;
    top: 2rem;
    right: 2rem;
    border-radius: 50%;
    z-index: 2000;
    box-shadow: 0 1rem 3rem rgba($color-black, 0.1);
    text-align: center;
    cursor: pointer;
    opacity: 0;
    display: none;
    @include respond(tab-land) {
      opacity: 1;
      display: inline-block;
    }
  }

  &__background {
    border-radius: 50%;
    position: fixed;
    height: 5rem;
    width: 5rem;
    top: 2rem;
    right: 2rem;
    background-image: radial-gradient(
      $color-secondary-light,
      $color-secondary-dark
    );
    z-index: 1000;
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    display: none;
    opacity: 0;
    @include respond(tab-land) {
      display: inline-block;
      opacity: 1;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1500;
    flex: 1;
    @include respond(tab-land) {
      height: 100vh;
      flex-direction: column-reverse;
      position: fixed;
      opacity: 0;
      width: 0;
      transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      top: 0;
      left: -100rem;
    }
    &__list {
      display: flex;
      list-style: none;
      flex: 1;
      @include respond(tab-land) {
        position: absolute;
        flex-direction: column;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        text-align: center;
        width: 100%;
      }
    }
    &__listitem {
      position: relative;
      z-index: 25;
      &:not(:last-child) {
        margin-right: $sp-16;
      }
      @include respond(tab-port) {
        margin: 1rem;
      }
    }
    &__link {
      &:link,
      &:visited {
        display: inline-block;
        font-size: $ft-18;
        font-weight: 400;
        padding: $sp-8 $sp-12;
        color: $color-primary;
        text-decoration: none;
        text-transform: uppercase;
        background-image: linear-gradient(
          120deg,
          transparent 0%,
          transparent 50%,
          $color-secondary-dark 50%
        );
        background-size: 220%;
        transition: all 0.4s;
        @include respond(tab-land) {
          color: $color-white;
          background-image: linear-gradient(
            120deg,
            transparent 0%,
            transparent 50%,
            $color-white 50%
          );
          font-size: $ft-30;
          font-weight: 300;
          padding: 1rem 2rem;
        }
      }
      &:hover,
      &:active,
      &.active {
        background-position: 100%;
        color: $color-white;
        transform: translateX(1rem);
        @include respond(tab-land) {
          color: $color-primary;
        }
      }
    }

    &__cta {
      display: flex;
      gap: $sp-12;
      align-items: center;
      @include respond(tab-land) {
        padding: 2rem 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-template-rows: auto;
        width: 50%;
      }
    }
  }

  //FUNCTIONALITY
  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;
    left: 0;
  }

  //ICON
  &__icon {
    position: relative;
    margin-top: 2.5rem;

    &,
    &::before,
    &::after {
      width: 2.5rem;
      height: 2px;
      background-color: $color-primary;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -0.8rem;
    }
    &::after {
      top: 0.8rem;
    }
  }

  &__button:hover &__icon::before {
    top: -1rem;
  }

  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
}
