@import "../../sass/common.scss";
.header {
  width: 90%;
  margin: 0 auto;
  padding: $sp-12 0;
  display: flex;
  justify-items: center;
  align-items: center;
  align-content: space-between;
  gap: $sp-24;
  z-index: 20;
  position: relative;
}

.hero {
  padding: $sp-96 $sp-128;
  max-width: 60%;
  @include respond(tab-port) {
    max-width: 70%;
  }
  @include respond(phone) {
    max-width: 100%;
    padding: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__description {
    font-size: $ft-20;
    margin: $sp-32 0;
  }
  &__cta {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: auto;
  }
}
