@mixin box-shadow($type) {
  @if $type==light {
    box-shadow: 0 0.5rem 0.5rem rgba($color-black, $alpha: 0.4);
  }
  @if $type==mid {
    box-shadow: 0 1rem 3.5rem rgba($color-black, $alpha: 0.8);
  }
  @if $type==dark {
    box-shadow: 0 1.5rem 2rem rgba($color-black, $alpha: 1);
  }
  @if $type==bottom1 {
    box-shadow: 0 15px 15px -15px rgba($color-black, 0.5);
  }
  @if $type==bottom2 {
    box-shadow: 0 10px 20px -15px rgba($color-black, 1);
  }
}

@mixin background-gradiant($direction, $variant) {
  @if $variant==primary {
    background-image: linear-gradient(
      $direction,
      $color-primary-light,
      $color-primary-dark
    );
  }
  @if $variant==secondary {
    background-image: linear-gradient(
      $direction,
      $color-secondary-light,
      $color-secondary-dark
    );
  }
  @if $variant==tertiary {
    background-image: linear-gradient(
      $direction,
      $color-tertiary-light,
      $color-tertiary-dark
    );
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 64em) {
      @content;
    } //1024px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == mid-desktop {
    @media only screen and (max-width: 112.5em) {
      @content;
    } //1800px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800px & above
  }
}
