@import "../../sass/common.scss";
.footer {
  background-color: $color-grey-dark;
  padding: $sp-16 0;
  &__content {
    width: 90%;
    margin: 0 auto;
    color: $color-white;
    font-size: $ft-16;
  }
  &__copyright {
    font-size: $ft-12;
  }
}
